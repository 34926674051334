<template>
  <section class="view-catalog-services">
    <!-- Intro -->
    <div class="view-catalog-services__intro">
      <div class="view-catalog-services__intro-content">
        <h2
          class="view-catalog-services__intro-title"
          v-html="title"
        ></h2>

        <p
          class="view-catalog-services__intro-description"
          v-html="description"
        ></p>
      </div>
    </div>

    <!-- Service selection -->
    <div class="view-catalog-services__services container">
      <card-service
        :disabled="isServiceDisabled(service) || loading"
        :key="service.name"
        :loading="loading"
        :value="service"
        @click="onClickService"
        v-for="service in servicesList"
      />
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import MixinCMS from '@/modules/catalog/mixins/cms'
import { default as CardService } from '@/modules/catalog/components/ui/card-service'

export default {
  name: 'ServicesView',

  components: {
    CardService,
  },

  inject: [
    '$cart',
    '$catalog',
    '$core',
    '$embed',
    '$user'
  ],

  mixins: [ MixinCMS ],

  data() {
    return {
      loading: false,
      scheduleVisible: true,
      scheduleService: null
    }
  },

  computed: {
    ...mapState({
      service: state => state['sayl-front.service'].service,
      services: state => state['sayl-front.service'].services,
    }),

    description() {
      return this.getCMSBlockContent('intro_description') ||
             this.$t('resto.catalog_services_description')
    },

    embed() {
      return this.$basil.get(this.$embed, 'embed.model')
    },

    servicesList() {
      return this.services.filter((s) => s.name !== 'eatin')
    },

    shop() {
      return this.$basil.get(this.$embed, 'shop.model')
    },

    title() {
      return this.getCMSBlockContent('intro_title') ||
             this.$t('resto.catalog_services_title')
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },
  },

  methods: {
    isServiceDisabled(service){
      return service.name === 'eatin' || !this.hasMenu(service)
    },

    hasMenu(service) {
      return this.$basil.get(service, 'menuId', null) != null
    },

    onClickService(value) {
      this.loading = true

      this.$core.changeService({ service: value.name })
        .then(() => {
          this.$catalog.catalog.catalog = {}

          this.$cart.cart = null
          this.$store.commit('sayl-front.cart/setCart', null)

          this.loading = false
          this.$router.push({
            name: 'sayl-front-catalog.catalog',
            params: {
              embed: this.embed.id,
              service: value.name,
            }
          })
          .catch(() => {})
        })
        .catch((error) => $console.error(error))
    },
  },

  mounted() {
    this.$bus.$emit('table:null', false);

    // Auto redirect if only one service
    if (this.services.length === 1){
      let service = this.services[0]
      if(service.name !== 'eatin') {
        this.onClickService(service)
      }
    }
  },
}
</script>
