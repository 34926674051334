import { mapState } from 'vuex'

export default {
  inject: ['$embed'],

  computed: {
    ...mapState({
      i18n: state => state['bootstrap'].i18n,
    }),

    embed() {
      return this.$basil.get(this.$embed, 'embed.model')
    },

    lang(){
      return this.i18n.locale.lang.toString()
    },

    cmsPlaceholders(){
      return this.$basil.get(this.embed, 'placeholders', [])
    }
  },

  methods: {
    getCMSBlockContent(key) {
      let b = this.cmsPlaceholders.find(p => p.key === key);
      let content = this.$basil.get(b, 'content', {});

      return this.$basil.get(content, this.lang, null) ||
             this.$basil.get(content, 'all', null)
    },
  }
}
