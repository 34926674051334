<template>
  <div
    :class="classes"
    @click="onClick"
    :disabled="disabled">
    <div
      class="card-service__header"
      :style="{ 'background-image': 'url('+ background +')' }">
      <ui-img
        class="card-service__illustration"
        :src="illustration"
        :alt="alt"
        v-if="!hasCMSIllustration"
      />

      <div
        class="card-service__illustration -is-placeholder"
        v-html="illustration"
        v-else
      ></div>

      <span class="card-service__cta">{{ $t('resto.catalog_explore') }}</span>
      <div
        v-if="loading"
        class="card-service__loader">
        <ui-loader />
      </div>
    </div>

    <div class="card-service__body">
      <h3
        class="card-service__title"
        v-html="title"></h3>

      <button class="card-service__openning -is-active">
        <!-- Open -->
        <div v-if="isOpen" class="card-service__state">{{ $t('resto.shop_open')}}</div>

        <!-- Open later -->
        <div v-if="!isOpen && isOpenLater" class="card-service__state">{{ $t('resto.shop_open_later')}}</div>

        <!-- Closed -->
        <div v-if="!isOpen && !isOpenLater" class="card-service__state">{{ $t('resto.shop_closed') }}</div>

        <!-- No menu id linked -->
        <div v-if="!hasMenu" class="card-service__state">{{ $t('resto.shop_service_no_menu')}}</div>
      </button>

      <p
        class="card-service__description"
        v-html="description"></p>
    </div>
  </div>
</template>

<script>
import Config from '@/config'
import MixinCMS from '../../mixins/cms'
import UiImg from '@/components/ui/img'

export default {
  name: 'CatalogServiceCard',

  components: { UiImg },

  inject: ['$embed'],

  mixins: [ MixinCMS ],

  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    },

    value: {
      type: Object
    }
  },

  computed: {
    alt() {
      return this.value.name + ' illustration';
    },

    background() {
      return Config.cdn + 'statics/images/services/pattern.png'
    },

    classes() {
      return {
        'card-service': true,
        '-is-disabled': this.disabled === true,
        '-is-loading': this.loading === true
      }
    },

    embed() {
      return this.$basil.get(this.$embed, 'embed.model')
    },

    hasMenu() {
      return this.$basil.get(this.value, 'menuId', null) != null;
    },

    hasCMSIllustration() {
      return !this.$basil.isNil(this.getCMSBlockContent(`${this.value.name}_picture`))
    },

    illustration() {
      return this.getCMSBlockContent(`${this.value.name}_picture`) ||
             Config.cdn + 'statics/images/services/' + this.value.name + '.svg';
    },

    /**
     * Is the shop open now
     *
     * @property {Boolean}
     */
    isOpen() {
      return this.value.isOpen
    },

    /**
     * Is the shop currently closed but will open later today
     *
     * @property {Boolean}
     */
    isOpenLater() {
      return this.value.isOpenLater
    },

    title() {
      return this.getCMSBlockContent(`${this.value.name}_title`) ||
             this.$t('resto.catalog_services_'+ this.value.name)
    },

    description() {
      return this.getCMSBlockContent(`${this.value.name}_description`) ||
             this.$t('resto.catalog_services_'+ this.value.name + '_description')

    }
  },

  methods: {
    onClick() {
      this.$emit('click', this.value);
    },
  },
}
</script>
